<template lang="pug">
  v-dropdown(
    width-auto
    auto-close)
    v-btn(icon)
      font-awesome-icon(
        size="xs"
        icon="bars")
    sidebar(
      elevated
      slot="content")

</template>

<script>
import Sidebar from '../sidebar/Sidebar'

export default {
  name: 'TabletMenu',

  components: {
    Sidebar
  }
}
</script>

<style lang="scss" scoped>
</style>
