<template lang="pug">
  div
    v-dropdown(
      ref="dropdown"
      width-auto
      position-right)
      v-btn(icon)
        font-awesome-icon(
          size="xs"
          icon="ellipsis-v")
      v-card(
        elevated
        slot="content")
          .app-settings
            h3.app-settings__title {{ 'base.menu' | translate }}
            .app-settings__section
              v-btn(
                ghost
                v-if="showSelectFarm"
                :disabled="$route.path === '/select-farm'"
                @click="$router.push('/select-farm')")
                  v-icon(
                    font-size="0.9"
                    icon="tractor"
                    position-left)
                  span.app-settings__btn-text {{ 'base.select_farm' | translate }}
              v-btn(
                ghost
                @click="openInfoModal")
                  v-icon(
                    font-size="1.1"
                    icon="info-circle"
                    position-left)
                  span.app-settings__btn-text {{ 'base.about' | translate }}

            h3.app-settings__title {{ 'base.language' | translate }}
            .app-settings__section
              lang-switcher

            v-btn(
              ghost
              danger
              @click="changePassword")
                span.app-settings__btn-icon
                  font-awesome-icon(
                    size="sm"
                    icon="key")
                span.app-settings__btn-text {{ 'actions.change_password' | translate }}
            .app-settings__section

            v-btn(
              ghost
              danger
              @click="logout")
                span.app-settings__btn-icon
                  font-awesome-icon(
                    size="sm"
                    icon="sign-out-alt")
                span.app-settings__btn-text {{ 'actions.logout' | translate }}

    v-dialog(
      max-width="300"
      v-model="showAppInfoModal")
      app-info(@close="showAppInfoModal = false")

    v-dialog(
      max-width="300"
      v-model="showChangePasswordModal")
      change-password(@close="showChangePasswordModal = false")

</template>

<script>
import AppInfo from '../modals/AppInfo'
import ChangePassword from '@/components/layouts/header/modals/ChangePassword.vue'
import LangSwitcher from './LangSwitcher'
import { mapGetters } from 'vuex'

export default {
  name: 'AppSettings',

  components: {
    AppInfo,
    ChangePassword,
    LangSwitcher
  },

  data: () => ({
    showAppInfoModal: false,
    showChangePasswordModal: false
  }),

  computed: {
    ...mapGetters([
      'user'
    ]),
    showSelectFarm () {
      return this.user.user.role === 'Administrator' || this.user.user.farm_count > 1
    }
  },

  methods: {
    openInfoModal () {
      this.showAppInfoModal = true
      this.$refs.dropdown.closeDropdown()
    },

    logout () {
      this.$store.dispatch('logout')
    },

    changePassword () {
      this.showChangePasswordModal = true
      this.$refs.dropdown.closeDropdown()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';
  .v-btn {
    &--disabled {
      &:not(.v-btn--icon) {
        background-color: transparent !important;
      }
    }
  }
  .app-settings {
    padding: 2rem;
    min-width: 20rem;
    &__title {
      font-size: 1.5rem;
      line-height: 1.8rem;
      font-weight: 500;
      color: $color-gray;
      margin-bottom: 1.5rem;
    }

    &__section {
      position: relative;
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $color-gray-light-2;
    }

    &__btn-icon {
      font-size: 1.8rem;
      margin-right: 1.3rem;
    }

    &__btn-text {
      font-size: 1.6rem;
      line-height: 1.8rem;
    }
  }
</style>
