<template lang="pug">
  header.app-header
    .app-header__wrapper
      .app-header__tablet-menu
        tablet-menu
      .app-header__logo
        .app-header__mobile-menu
          v-btn(
            icon
            @click="openMobileMenu")
            font-awesome-icon(
              size="xs"
              icon="bars")

        router-link(to="/").app-header__logo-link PROFEED PLANT

      .app-header__profile-wrap
        p.app-header__profile-name {{ user.user.full_name }}
        p.app-header__current-farm(
          v-if="currentFarm") {{ $t('base.at_farm', { name: currentFarmName }) }}
        app-settings

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import AppSettings from './app-settings/AppSettings'
import TabletMenu from '../menu/TabletMenu'

export default {
  name: 'AppHeader',

  components: {
    AppSettings,
    TabletMenu
  },

  methods: {
    ...mapActions([
      'openMobileMenu'
    ])
  },

  computed: {
    ...mapGetters(['user', 'farms', 'currentFarm']),

    currentFarmName () {
      const farm = this.farms.find(item => item.id === this.currentFarm)

      return farm !== undefined ? farm.name : ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
