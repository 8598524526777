<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'base.about' | translate }}
    v-card-content
      .app-info__logo
      .app-info__item
        .app-info__item-label {{ 'base.version' | translate }}
        .app-info__item-value {{ appVersion}}
      .app-info__item
        .app-info__item-label {{ 'base.updated' | translate }}
        .app-info__item-value 03.02.2022
    v-card-actions(center)
      v-btn(
        @click="$emit('close')") OK

</template>

<script>
import { name, version } from '../../../../../package.json'

export default {
  name: 'AppInfo',

  computed: {
    appName () {
      return name
    },

    appVersion () {
      return version
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .app-info {
    &__logo {
      margin: 2.5rem auto;
      height: 81px;
      width: 125px;
      background-image: url('../../../../assets/svg/pepafeed_logo.svg');
    }

    &__item {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 1.2rem;
      }

      &-label {
        color: $color-gray;
        font-size: 1.5rem;
      }

      &-value {
        font-size: 1.5rem;
      }
    }
  }
</style>
