<template lang="pug">
  .app-wrapper
    mobile-menu(
      v-if="isMobile")

    .app-view(:class="{ 'app-view--minimized': isMobileMenuOpened }")
      app-header.app-header--fixed
      .scroll-wrapper
        vue-scroll
          .container
            router-view

</template>

<script>
import { mapGetters } from 'vuex'

import AppHeader from '@/components/layouts/header/AppHeader'

import adaptationMixin from '@/mixins/adaptation.mixin.js'

export default {
  name: 'AppMain',

  mixins: [adaptationMixin],

  components: {
    AppHeader,
    MobileMenu: () => import('@/components/layouts/menu/MobileMenu')
  },

  computed: {
    ...mapGetters(['isMobileMenuOpened'])
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';
  @import '@/sass/abstracts/_variables.scss';

  .app-wrapper {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }

  .app-view {
    position: absolute;
    width: 100%;
    z-index: 100;
    background-color: $color-gray-light-3;

    &--minimized {
      transform: translateX(60%) scale(.8);
      border-radius: 1rem;
      overflow: hidden;
      filter: drop-shadow(-15px 5px 30px rgba(0, 0, 0, 0.1));
    }
  }

  .scroll-wrapper {
    margin-top: $app-header-height;
    height: calc(100vh - #{$app-header-height});

    @include mobile {
      margin-top: $app-header-height - 1.5rem;
      height: calc(100vh - #{$app-header-height - 1.5rem});
    }
  }
</style>
