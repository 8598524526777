<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'actions.change_password' | translate }}
    v-card-content
      v-password-field.mb-3(
        :label="$t('base.password')"
        v-model="form.current_password")
      v-password-field.mb-3(
        :label="$t('base.new_password')"
        v-model="form.password")
      v-password-field.mb-3(
        :label="$t('base.repeat_password')"
        v-model="form.password_confirmation")
    v-card-actions
      v-btn.mr-2(
        :loading="loading"
        @click="send") {{ 'actions.send' | translate }}
</template>

<script>
import api from '@/api'

export default {
  name: 'ForgotPassword',

  mounted () { },
  data: () => ({
    form: {
      current_password: null,
      password: null,
      password_confirmation: null
    },
    options: { },
    loading: false
  }),

  methods: {
    send () {
      this.loading = true
      api.auth.changePassword(this.form)
        .then(response => {
          this.loading = false
          if (!response.error) {
            this.$emit('close')
          }
          this.$toasted.show(response.data.error, {
            position: 'bottom-center',
            duration: 5000
          })
          for (const key in response.data.errors) {
            this.$toasted.show(response.data.errors[key], {
              position: 'bottom-center',
              duration: 5000
            })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
